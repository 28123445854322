<template>
  <div class="edition-class">
    <img src="../assets/success.png" alt="" srcset="" />
    <div class="text-div">版本：1.5.1</div>
    <div class="text-div">
      介绍：介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍介绍
    </div>
    <div class="copyright-div">2020 - 2021 © aaabbb</div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.edition-class {
  width: 100%;
  height: calc(100% - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .text-div {
    max-width: 80%;
    margin: 20px 0px;
  }
  .copyright-div {
    width: 100%;
    position: fixed;
    bottom: 80px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>